import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ProjectDetails from "../../images/projects/BF-Aqua.yml"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template,
} from "../../page-components/projects/template"

const BloomfieldAqua = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query(
      $floorPlan: String = "/images/projects/bloomfield-aqua/04 FLOOR PLANS/"
    ) {
      logo: file(
        relativePath: {
          eq: "projects/bloomfield-aqua/BLOOMFIELD AQUA LOGO.png"
        }
      ) {
        ...ImgL
      }
      siteDevImg: file(
        relativePath: {
          eq: "projects/bloomfield-aqua/03 SITE DEVELOPMENT PLAN/BF AQUA SDP.png"
        }
      ) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "HouseUnits", num: "55" },
    { metric: "TypesofUnits", num: "1" },
    { metric: "TypicalLotArea", num: "80" },
    { metric: "TypicalFloorArea", num: "60" },
  ])
  const props: LocationTemplateProps = {
    title: "Bloomfield Aqua",
    logo,
    logoSz: 120,
    location: "Brgy. Sta. Rita, Macabebe, Pampanga",
    metrics,
    carouselImgs: [
      require("../../images/projects/bloomfield-aqua/CAROUSEL PHOTOS/01 BF AQUA ENTRANCE.png"),
      require("../../images/projects/bloomfield-aqua/CAROUSEL PHOTOS/02 BF AQUA GATE.png"),
      require("../../images/projects/bloomfield-aqua/CAROUSEL PHOTOS/03 BF AQUA STREET VIEW.png"),
      require("../../images/projects/bloomfield-aqua/CAROUSEL PHOTOS/04 BF AQUA STREET 2.png"),
      require("../../images/projects/bloomfield-aqua/CAROUSEL PHOTOS/05 BF AQUA AMENITIES VIEW.png"),
    ],
    siteDev: {
      img: siteDevImg,
      txt:
        "Bloomfield Aqua enjoys its privacy with its 55-unit community. Homeowners are able to easily maneuver and find their way around with its simple wayfinding. Pleasant views and fresh air is a benefit to enjoy with its strategic location.",
    },
    amenities: {
      description:
        "Bloomfield Aqua has several amenities that are within easy access to its homeowners. The community is fully equipped with its own private clubhouse, swimming pool, chapel, and jogging path. BF Aqua makes it easy for the community to have comfortable living accompanied by its great views.",
      items: [
        {
          name: "Chapel",
          description:
            "The Chapel is the perfect place to find inner peace and find comfort in silence. The all glass facade allows the homeowners to be surrounded by the tranquil waters. ",
          img: require("../../images/projects/bloomfield-aqua/06 AMENITIES _ FEATURES/01 BF AQUA CHAPEL.png"),
        },
        {
          name: "Clubhouse",
          description:
            "Make your events even more memorable in the open-air clubhouse, available for everyone's use. ",
          img: require("../../images/projects/bloomfield-aqua/06 AMENITIES _ FEATURES/02 BF AQUA CLUBHOUSE.png"),
        },
        {
          name: "Swimming Pool",
          description:
            "The swimming pool is the perfect place to relax and enjoy a day under the sun.",
          img: require("../../images/projects/bloomfield-aqua/06 AMENITIES _ FEATURES/03 BF AQUA SWIMMING POOL.png"),
        },
        {
          name: "Outdoor Area",
          description:
            "Get to know your community by hanging out in the designated outdoor areas.",
          img: require("../../images/projects/bloomfield-aqua/06 AMENITIES _ FEATURES/04 BF AQUA OUTDOOR AREA.png"),
        },
      ],
    },
    floorPlans: {
      description: `Bloomfield Aqua has a single type of unit to offer, a single attached 2-storey unit with a typical lot area of 80sqm and a typical floor area of 60sqm. Homeowners are also given the freedom to expand by means of construction of a roof deck over their designated carports.

        ** All Floor Plans below are for suggested layout only.`,
      items: [
        [
          {
            name: "Single Attached",
            fpImg: fpImg("BF AQUA FP"),
            pImg: fpImg("01 BF AQUA SA"),
          },
        ],
      ],
    },
    projDetails: {
      ...ProjectDetails,
      name: "Bloomfield Aqua",
      description:
        "Bloomfield Aqua is strategically located in Macabebe, Pampangato enjoys its surrounding nature. It has close proximity to several establishments, and contains several in house amenities that makes Bloomfield your dream location.",
    },
  }
  return <Template {...props} />
}

export default BloomfieldAqua
